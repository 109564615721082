import {
    useEditController,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    TextField,
    SelectField,
    usePermissions
} from 'react-admin';
import Button from '@mui/material/Button';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, createTheme } from '@mui/material/styles';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const theme = createTheme({
//     button: {
//         margin: theme.spacing(1)
//     }
//   });

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            {/* <EditButton basePath = {props.basePath} record={props.data} /> */}
            <Button variant="contained" onClick={() => navigate("/contacts")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}

const ContactShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.contacts.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <TextInput source="salutation"
                    disabled
                    label={translate('resources.contacts.fields.salutation')}
                    helperText={translate('resources.contacts.helperText.salutation')}
                    fullWidth />
                <TextInput source="name"
                    disabled
                    label={translate('resources.contacts.fields.name')}
                    helperText={translate('resources.contacts.helperText.name')}
                    fullWidth />
                <TextInput source="nric"
                    disabled
                    label={translate('resources.contacts.fields.nric')}
                    helperText={translate('resources.contacts.helperText.nric')}
                    fullWidth />
                <TextInput source="contact_number"
                    disabled
                    label={translate('resources.contacts.fields.contact_number')}
                    helperText={translate('resources.contacts.helperText.contact_number')}
                    fullWidth multiline />
                <TextInput source="email"
                    disabled
                    label={translate('resources.contacts.fields.email')}
                    helperText={translate('resources.contacts.helperText.email')}
                    fullWidth multiline />
                <TextInput source="gender"
                    disabled
                    label={translate('resources.contacts.fields.gender')}
                    helperText={translate('resources.contacts.helperText.gender')}
                    fullWidth />
                <TextInput source="marital"
                    disabled
                    label={translate('resources.contacts.fields.marital')}
                    helperText={translate('resources.contacts.helperText.marital')}
                    fullWidth />
                <TextInput source="dob"
                    disabled
                    label={translate('resources.contacts.fields.dob')}
                    helperText={translate('resources.contacts.helperText.dob')}
                    fullWidth />
                <TextInput source="address"
                    disabled
                    label={translate('resources.contacts.fields.address')}
                    helperText={translate('resources.contacts.helperText.address')}
                    fullWidth />
                <TextInput source="remark"
                    disabled
                    label={translate('resources.contacts.fields.remark')}
                    helperText={translate('resources.contacts.helperText.remark')}
                    fullWidth />
            </SimpleForm>
        </Show>
    )
}

export default ContactShow;