import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PromotionList from './promotion-list'
import PromotionCreate from './promotion-create'
import PromotionShow from './promotion-show'
import PromotionEdit from './promotion-edit';

export default {
    create: PromotionCreate,
    // edit: PromotionEdit,
    show: PromotionShow,
    list: PromotionList,
    icon: LoyaltyIcon,
}