import { useEffect, useState, useRef } from 'react';
import {
    AutocompleteInput,
    Button,
    Edit,
    email,
    maxLength,
    minLength,
    PasswordInput,
    RaRecord,
    regex,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useNotify,
    useRedirect,
    useTranslate,
} from 'react-admin';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = (props: any) => {
    const navigate = useNavigate();
    return (
        <Toolbar {...props} >
            <SaveButton />
            <Button label="Cancel" sx={{ m: 1, padding: 1 }} onClick={(e) => { e.preventDefault(); navigate("/users"); }} variant="contained" startIcon={<CancelIcon />} />
        </Toolbar>
    )
}

/**
 * User Create Form
 * 
 * @returns UserCreate
 */
const UserEdit = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const [roleChoices, setRoleChoices] = useState<any[]>([]);
    const [agentChoices, setAgentChoices] = useState<any[]>([]);
    const [isLiveAgent, setIsLiveAgent] = useState<any>(true);
    const [errors, setErrors] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const isMounted = useRef(false); // useRef will always keep the previous stage even it is re-render

    // Field validators setup
    const validateUsername = [
        required(translate('resources.user.errors.username.required')), 
        minLength(3, translate('resources.user.errors.username.minLength')),
        maxLength(20, translate('resources.user.errors.username.maxLength')),
        //regex(/^\S*$/, 'non whitespace')
        regex(/^[a-zA-Z]+[a-zA-Z0-9@_.]+$/, translate('resources.user.errors.username.regex')),
    ];
    const validateFullname  = [
        required(translate('resources.user.errors.fullname.required')), 
        minLength(3, translate('resources.user.errors.fullname.minLength')),
        maxLength(50, translate('resources.user.errors.fullname.maxLength')),
    ];
    const validateEmail = [
        email(translate('resources.user.errors.email.invalid')),
        maxLength('resources.user.errors.email.maxLength'),
    ]
    const validateRole = [required(translate('resources.user.errors.role.required'))];
    const validatePassword = [
        required(translate('resources.user.errors.password.required')), 
        minLength(translate('resources.user.errors.password.minLength')),
        maxLength(translate('resources.user.errors.password.maxLength')),
        (value: any, allValues: any, props: any) => {
            if (allValues.password !== allValues.confirm_password) {
                return 'resources.user.errors.password.notMatched';
            } 
        }, 
        regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%()^&*~])/, translate('resources.user.errors.password.regex'))
    ];

    useEffect(() => {
        dataProvider.getMany('fetch-agents-choices', { ids: [] })
            .then(({ data }) => {
                let choices: any[] = [];
                data?.map(record => choices.push({
                    id: record.id,
                    name: record.agent_name,
                }));
                setAgentChoices(choices);
            })
            .catch(error => {
                notify(error)
            })
    }, []);

    useEffect(() => {
        dataProvider.getMany('fetch-roles-choices', { ids: [] })
            .then(({ data }) => {
                let choices: any[] = [];
                data?.map(record => choices.push({
                    id: record.id,
                    name: record.role_name,
                }));
                setRoleChoices(choices);
            })
            .catch(error => {
                notify(error)
            })
    }, []);

    const onSuccess = (data: RaRecord) => {
        notify('resources.user.notification.updated')
        redirect('list')
    }

    const onError = (error: any) => {
        setErrors( [] )
        if (typeof error === 'string')
            setErrors( (state) => [...state, error])
        else if (error.body?.detail) {
            if (typeof error.body.detail === 'string')
                setErrors( (state) => [...state, error])
            else if (error.body.detail.length > 0) {
                const error_detail:any[] = []
                error.body.detail.map( (item: any) => error_detail.push(`${item.loc[1]} - ${item.msg}`))
                setErrors( (state) => [...state, ...error_detail])
            }
        }
    }

    return (
        <Edit title={translate('resources.user.form.edit')} >
            <SimpleForm
                toolbar={<BottomToolbar />}
                sx={{ maxWidth: 500 }}
                // Here for the GQL provider
                // defaultValues={{
                //     birthday: new Date(),
                //     first_seen: new Date(),
                //     last_seen: new Date(),
                //     has_ordered: false,
                //     latest_purchase: new Date(),
                //     has_newsletter: false,
                //     groups: [],
                //     nb_commands: 0,
                //     total_spent: 0,
                // }}
            >
                {errors.length > 0 ? 
                    <>
                      <Stack sx={{width: '100%'}} spacing={1}>
                        {errors.map( (err) => (<Alert severity="error">{err}</Alert>) )}
                      </Stack>
                      <Separator />
                    </>
                    : null
                }
                <SectionTitle label="resources.user.field_groups.identity" />
                <TextInput source="username" fullWidth resettable={true} validate={validateUsername} />
                <TextInput source="fullname" fullWidth resettable={true} />
                <TextInput source="email" type="email" fullWidth resettable={true} validate={validateEmail}/>
                <Separator />
                <SectionTitle label="resources.user.field_groups.security_access" />
                <AutocompleteInput fullWidth source="role_id" validate={validateRole} choices={roleChoices} />
                {
                    isLiveAgent && 
                    <AutocompleteInput fullWidth source="live_agent_id" choices={agentChoices} />
                }
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput source="password" fullWidth resettable={true} validate={validatePassword} />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput source="confirm_password" fullWidth resettable={true}  />
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
};

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserEdit;