import { 
    useTranslate, 
    TextInput, 
    ShowButton, 
    FilterButton,
    useListContext, 
    TopToolbar, 
    usePermissions,
    List, 
    Datagrid, 
    TextField, 
    RichTextField, 
    Pagination,
    useRedirect, 
    FunctionField, 
    CreateButton,
    Button
} from 'react-admin'
import {cloneElement} from 'react'
import { useNavigate } from 'react-router-dom' 
import { CustomDateField, CustomDateTimePicker } from '../custom-components'
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import MessageIcon from '@mui/icons-material/Message';
import { useState, useMemo } from 'react'; 
import RowNumberField from './rowNumber';
import { BACKEND_URL } from '../constants';
// import IconButton from '@material-ui/core/IconButton'
// import { sendTemplateMessage } from '../redux/actions'
// import { template_message } from '../redux/store'
// import Icon from '@material-ui/core/Icon'

// Filters for the Contact listing
const LeadsFilter = [
    <TextInput source="name"/>,
    <TextInput source="nationality"/>,
    <TextInput source="contact_number"/>,
    <TextInput source="email"/>
]

const downloadCSV = async () => {
    const apiUrl = `${BACKEND_URL}/leads/export`;
    fetch(apiUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'leads.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        })
}

// Paginator for the Contact listing
const LeadsPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const LeadsList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const [prevNumber, setPrevNumber] = useState(0);
    const [rowNumber, setRowNumber] = useState(prevNumber + 1);
    
    const rowNumberCounter = useMemo(() => {
        let count = prevNumber;
        return () => ++count;
    }, []);

    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    const ListActions = () => (
        <TopToolbar>
            <Button 
                label="Export" 
                onClick={downloadCSV}
                startIcon={<DownloadIcon/>}
            />
            <FilterButton/>
        </TopToolbar>
    )

    return (
        <List actions={<ListActions />} filters={LeadsFilter} title={translate('resources.leads.form.list')} bulkActionButtons={false} exporter={false} pagination={<LeadsPaginator/>}>
            <Datagrid>
                <TextField label="id" source="id"/>
                <TextField label={translate('resources.leads.fields.created_at')} source="created_at"/>
                <TextField label={translate('resources.leads.fields.name')} source="name"/>
                <TextField label={translate('resources.leads.fields.contact_number')} source="contact_number"/>
                <TextField label={translate('resources.leads.fields.email')} source="email"/>
                <TextField label={translate('resources.leads.fields.nationality')} source="nationality"/>
                <TextField label={translate('resources.leads.fields.bot_channel')} source="bot_channel"/>
                {/* <ShowButton /> */}
            </Datagrid>
        </List>
    )
}

export default LeadsList