import { useState, useEffect }  from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { VictoryPie } from "victory";
import { BACKEND_URL } from '../../constants'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default function CourseNonCourseEnquiry({selectedStartDate, selectedEndDate}: any) {
  const [basis, setBasis] = useState('daily');
  const [coursesCount, setCoursesCount] = useState(0);
  const [nonCoursesCount, setNonCoursesCount] = useState(0);
  const [coursesPercentage, setCoursesPercentage] = useState(0);
  const [nonCoursesPercentage, setNonCoursesPercentage] = useState(0);

  const [chartData, setChartData] = useState({
    labels: ['Course','Non Course'],
    datasets: [
      {
        data: [
          coursesCount, nonCoursesCount
        ],
        backgroundColor: [
          '#ff8f00',
          '#ffecb3',
        ],
        borderColor: [
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
  });
  
  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-course-and-non-course-enquiry?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newData = {
          labels: ['Course','Non Course'],
          datasets: [
            {
              data: [
                data.coursesCount, data.nonCoursesCount
              ],
              backgroundColor: [
                '#ff8f00',
                '#ffecb3',
              ],
              borderColor: [
                'white',
                'white',
              ],
              borderWidth: 2,
            },
          ],
        };
        setChartData(newData);
        setCoursesPercentage(data.coursesPercentage)
        setNonCoursesPercentage(data.nonCoursesPercentage)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  const options = {
    cutout: 70, // set the cutoutPercentage to 70 to create a custom inner radius
  };

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
          <Stack alignItems='center' direction="row">
            <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Courses</Typography>
          </Stack>
        </Box>
      <br/>
      <br/>
        <Stack flexDirection="row" justifyContent="center">
        <Box width={200} height={200}>
          <Doughnut data={chartData} options={options}/>
          </Box>
        </Stack>
      <br/>
      <br/>
      <Stack sx={{ m:2 }} divider={<Divider variant="middle" orientation="horizontal" flexItem />} alignItems="center" spacing={1} direction="column">
          <Stack spacing={10} justifyContent="flex-start"  direction="row">
            <Stack spacing={2} alignItems="center" direction="row">
              <Badge variant="dot">
                <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#ff8f00' }} />
              </Badge>  
              <Typography variant="body1">Course</Typography>
            </Stack>
            <Typography variant="body1">{coursesPercentage}%</Typography>
          </Stack>
          <Stack spacing={10} justifyContent="flex-start"  direction="row">
            <Stack spacing={2} alignItems="center" direction="row">
              <Badge variant="dot">
                <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#ffecb3' }} />
              </Badge>  
              <Typography variant="body1">Non Course</Typography>
            </Stack> 
            <Typography variant="body1">{nonCoursesPercentage}%</Typography>
          </Stack>
      </Stack>
    </Paper>
  );
};
