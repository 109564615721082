import React from "react";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLegend, VictoryLabel } from "victory";
import { BACKEND_URL } from '../../constants'
import { useState, useEffect }  from 'react';
import { List } from "lodash";
import { Stack, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';

export default function ProgramCompositionChart({selectedStartDate, selectedEndDate}: any) {
  const [chartData, setChartData] = useState([
    {
        "type": "Arts",
        "data": [
            {
                "intent": "bachelor_accounting",
                "count": 1
            },
        ]
    },
    {
        "type": "Science",
        "data": [
            {
                "intent": "bachelor_chemical_engineering",
                "count": 2
            },
        ]
    },
    {
        "type": "Hybrid",
        "data": [
            {
                "intent": "master_computer_science",
                "count": 2
            }
        ]
    }
]);
  const [basis, setBasis] = useState(10);

  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  function convertProgramToText(intent: string) {
    const words = intent.split("_");
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const outputString = capitalizedWords.join(" ");

    return outputString
  }
  
  function generateColorList(dataLength: number, colorTone: number) {
    var colorList = [];
    var hue = colorTone; // set the hue to the desired color tone
  
    // loop through the data length and generate a color for each index
    for (var i = 0; i < dataLength; i++) {
      var saturation = 50 + Math.random() * 50; // set the saturation to a random value between 50 and 100
      var lightness = 50 + Math.random() * 25; // set the lightness to a random value between 50 and 75
  
      // create the color using HSL notation
      var color = "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
  
      // add the color to the color list
      colorList.push(color);
  
      // increment the hue slightly for each index to create a gradient effect
      hue += 10;
    }
    return colorList;
  }

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-programs-most-enquired?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newData = data;
        setChartData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  const options = {
    cutout: 50, // set the cutoutPercentage to 70 to create a custom inner radius
  };

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
        <Stack alignItems='center' direction="row">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="h5">Programs Enquiries
          </Typography>
          {/* <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Qualifications</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput label="Qualifications" />}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Stack>
      </Box>
      <br/>
      <br/>
      <Stack flexDirection="row" alignItems="flex-end" justifyContent="space-around">
        {chartData.map((courseData) => (
            <Box>
              <Box width={250} height={250}>
                <Doughnut 
                  options={options}
                  data={{
                  labels: courseData?.data.map((item) => convertProgramToText(item.intent)),
                  datasets: [
                    {
                      data: courseData?.data.map((item) => item.count),
                      backgroundColor: generateColorList(courseData?.data.length, 200),
                    }
                  ]
                }}/>
              </Box>
              <br/>
              <Typography align='center' variant="body1">{courseData.type}</Typography>
            </Box>
          ))}
      </Stack>
      <br/>
      <br/>
    </Paper>
  );
};


