
import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { BACKEND_URL, ENVIRONMENT }  from './constants';


const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: "application/json"
        });
    }
    const token = sessionStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(url, options);
}

const jsonDataProvider = jsonServerProvider(BACKEND_URL, httpClient);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images/files in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
 const convertFileToBase64 = (file: any) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });


const uploadMultipleFiles = (resource: any , params: any) => {
    const files = []

    if (params.data.file_en){
        params.data.file_en['attachment_type'] = 'attachment_en'
        files.push(params.data.file_en)
    }
    if (params.data.customer_file){
        params.data.customer_file['attachment_type'] = 'customer_file'
        files.push(params.data.customer_file)
    }

    const newFiles = files.filter(
        p => p.rawFile instanceof File
    );

    return Promise.all(newFiles.map(convertFileToBase64))
        .then(base64Files =>
            base64Files.map((base64File, index) => ({
                    src: base64File,
                    filename: newFiles[index].title,
                    attachment_type: newFiles[index].attachment_type,
                }))
        )
        .then(transformedNewFiles =>  {
            return jsonDataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    'files': transformedNewFiles
                },
            })
        }
    )
}

const dataProvider = {
    ...jsonDataProvider,
    
    // Handle singe product and customer file upload on POST request
    create: (resource:any, params:any) => {

        if ((resource === "promotions") && (params.data.file_en || params.data.customer_file)) {
            return uploadMultipleFiles(resource, params)
        } else if ((resource === "template") && (params.data.file_en)) {
            return uploadMultipleFiles(resource, params)
        } else {
            // Fallback to the default implementation of POST request if not matched above resources
            return jsonDataProvider.create(resource, params)
        }
    },

}

export default dataProvider;
export { httpClient }