import React, {useEffect, useState, useCallback} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength, TabbedForm, FormTab, FileInput, FileField, ArrayInput, SimpleFormIterator, DateTimeInput, SimpleList } from 'react-admin';
import dataProvider from '../data-provider'
import botResponse from "."
import { BACKEND_URL } from '../constants'
import { InputLabel } from '@mui/material';
import axios from 'axios';
import GetAppIcon from '@mui/icons-material/GetApp';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))
                                                                                                                              


/***
 * Field validator for matching Password and Confirm Password fields
 */
// const checkPassword = () => (value, allValues, props) => {
//     if (allValues.password !== allValues.confirm_password) {
//         return 'resources.user.errors.password.notMatch'
//     }
//     return undefined
// }

const ContactCreate = ({access_token}: any) => { 
    const [selectedFile, setSelectedFile] = useState(null);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setSaveDisabled(false);
    };

    const handleContactUpload = (event: any, selectedFile: any) => {
        const formData = new FormData();
        const token = sessionStorage.getItem('token')
        formData.append('upload_file', selectedFile);

        if (token && selectedFile) {
            formData.append('file', selectedFile)
            // axios
            //     .post(`${BACKEND_URL}/contacts`, formData)
            //     .then(res => console.log(res))
            //     .catch(err => console.warn(err));
            axios
                .post(`${BACKEND_URL}/contacts`,
                        formData,
                        {headers: { 'Content-Type': 'multipart/form-data' }}
                )
                .then(res => console.log(res))
                .catch(err => console.warn(err));
        }
        setSelectedFile(null)
        notify('Contacts Uploaded')
    }
    console.log(selectedFile)

    const BottomToolbar = ({saveDisabled}: any) => {
        return (
            <Toolbar >
                <SaveButton alwaysEnable onClick={(e: any) => { handleContactUpload(e, selectedFile) }}/>
                <Button variant="contained" onClick={() => redirect('/contacts')} startIcon={<CancelIcon />}>
                    Cancel
                </Button>
            </Toolbar>
        )
    }

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    // const transformRecord = (data) => {
    //     for (var i = 0; i < data.broadcast_date.length; i++) {
    //         data.broadcast_date[i].converted_date = moment(data.broadcast_date[i].date).format("YYYY-MM-DD HH:mm")
    //     }
    //     return {
    //         ...data
    //     }
    // }

    return (

            <SimpleForm toolbar={<BottomToolbar/>}>
                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="pic" id="fileUpload" style={{ visibility: "hidden", overflow: "hidden" }} type="file" multiple={false} onChange={changeHandler} />
                <label htmlFor="fileUpload">
                    <Button color="primary" component="span" size="large" startIcon={<GetAppIcon/>}>Import Contact List</Button>
                </label>
                <br/>
                <br/>
                <InputLabel><a href={BACKEND_URL + '/fetch-template?file=GEM_Contact_Listing_Template.xlsx'} download>Download Contact List Template</a></InputLabel>
            </SimpleForm>

    )
}

export default ContactCreate
