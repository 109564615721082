import { 
    Datagrid,
    List, 
    Pagination,
    TextField, 
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
    Button,
    useNotify,
} from 'react-admin';
import { useState, useEffect } from 'react';
import RocketIcon from '@mui/icons-material/Rocket';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { BACKEND_URL, ENVIRONMENT } from '../constants';

const ModelPaginator = (props: any) => <Pagination rowsPerPageOptions={[5,10,15]} {...props}/>

const ModelList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        isLoading,
    } = listContext;
    const notify = useNotify();
    const [trainingInProgress, setTrainingInProgress] = useState(false)
    const [activationInProgress, setActivationInProgress] = useState(false)


    const checkIfTrainingInProgress = () => {
        const url = `${BACKEND_URL}/train/training_status`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.is_training){
                setTrainingInProgress(true)
            } else {
                setTrainingInProgress(false)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const checkIfActivationInProgress = () => {
        const url = `${BACKEND_URL}/train/activation_status`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.is_activating){
                setActivationInProgress(true)
            } else {
                setActivationInProgress(false)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(() => {
        // check once load
        checkIfTrainingInProgress()
        checkIfActivationInProgress()

        // check every 5 seconds
        const interval = setInterval(() => {
            checkIfTrainingInProgress()
            checkIfActivationInProgress()
        }, 5000);
        return () => clearInterval(interval);
    }, []);
    

    const train = (url: string) => {
        setTrainingInProgress(true)
        const token = sessionStorage.getItem('token');
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({}),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message == 'started'){
                notify('resources.model.actions.train.started')
            } else if (data.message == 'in progress'){
                notify('resources.model.actions.train.error.in_progress')
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const trainModelCore = () => {
        const url = `${BACKEND_URL}/train/all`
        train(url)
    }

    const activateLatest = () => {
        setActivationInProgress(true)
        notify('resources.model.actions.activate.started')
        const token = sessionStorage.getItem('token');
        const url = `${BACKEND_URL}/train/activate`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => response.json())
        .then(data => {
            notify('resources.model.actions.activate.success')
            console.log(data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const pushModel = () => {
        const token = sessionStorage.getItem('token');
        const url = `${BACKEND_URL}/model/push`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => response.json())
        .then(data => {
            notify('resources.model.actions.push.success')
            console.log(data)
        });
    }

    const pullModels = () => {
        const url = `${BACKEND_URL}/model/pull`
        const token = sessionStorage.getItem('token');
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.message == 'started'){
                notify('Started pulling models from staging')
            } else if (data.message == 'in progress'){
                notify('Pulling models from staging')
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const ListActions = ({permissions}: {permissions: any}) => (
        <TopToolbar>
            {
                ENVIRONMENT == 'staging' ?
                <>
                <Button 
                    label={translate('resources.model.actions.push.title')}
                    onClick={pushModel} 
                    startIcon={<CloudUploadIcon/>}
                />
                <Button 
                    label={trainingInProgress ? "resources.model.actions.train.in_progress" : "resources.model.actions.train.title"}
                    onClick={trainModelCore} 
                    disabled={trainingInProgress}
                    startIcon={<RocketIcon/>}
                />
                </>
                :
                <Button 
                    label={translate('resources.model.actions.pull.title')}
                    onClick={pullModels} 
                    startIcon={<RocketIcon/>}
                />
            }
            <Button 
                label={activationInProgress ? "resources.model.actions.activate.in_progress" : "resources.model.actions.activate.title"}
                onClick={activateLatest} 
                disabled={activationInProgress}
                startIcon={<TipsAndUpdatesIcon/>}
            />
        </TopToolbar>
    )


    return (
        <List actions={<ListActions permissions={permissions}/>} title={translate('resources.model.form.list')} exporter={false} pagination={<ModelPaginator/>} perPage={15} queryOptions={{ refetchInterval: 5000 }}>
            <Datagrid>
                <TextField label={translate('resources.model.fields.name')} source="name"/>
                <TextField label={translate('resources.model.fields.date')} source="date"/>
                <TextField label={translate('resources.model.fields.state')} source="state"/>
            </Datagrid>
        </List>
    )
}

export default ModelList;
