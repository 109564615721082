import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    createContext,
    useContext,
    useReducer,
    useMemo,
    useRef
} from 'react'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReactLoading from "react-loading";

import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import ConversationContext from '../context/ConversationContext';
import BadgeAvatars from './components/badge-avatar';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import { AnyNsRecord } from 'dns';
import InfiniteScroll from "react-infinite-scroll-component";

const ConversationItem = ({ key, handleConversationClick, conversationIndex, sender_id, created_at, conversation_tag, bot_channel, user_message_count, room_id, agent_id, clock_color, session_status, time_epoch, university_id, sender_name }: any) => {
    const { messages, selectedSenderId }: any = useContext(ConversationContext)

    const [userMessageCount, setUserMessageCount]: any = useState(0)
    const [newMessage, setNewMessage]: any = useState(false)

    const userMessageCountRef = useRef(user_message_count)

    const current_agent_id = sessionStorage.getItem('agent_id')

    const audioPlayer = useRef<HTMLAudioElement>(null);

    function playAudio() {
        if (audioPlayer.current) {
            audioPlayer.current.play();
        }
    }

    useEffect(() => {
        if (agent_id == current_agent_id && user_message_count > userMessageCountRef.current) {
            setNewMessage(true)
            if (conversation_tag != 'Online') {
                playAudio();
            }
        }
    }, [user_message_count]);

    useEffect(() => {
        if (sender_id === selectedSenderId) {
            resetCounts()
        }
    }, [messages]);

    function resetCounts() {
        setUserMessageCount(0)
        userMessageCountRef.current = user_message_count
    }

    function getSenderId(sender_id: string) {
        try {
            return sender_id.substring(0, 14)
        } catch {
            return sender_id
        }

    }

    function getSenderName(sender_name: string) {
        try {
            if (sender_name.length > 14) {
                const nameParts = sender_name.split(' ');
                if (nameParts.length > 0) {
                    return nameParts[0];
                } else {
                    return sender_name;
                }
            } else {
                return sender_name;
            }
        } catch {
            return sender_name;
        }
    }



    function getDifference(a: number, b: number) {
        return Math.abs(a - b);
    }

    useEffect(() => {
        if (newMessage == true) {
            let a: number = user_message_count
            let b: number = userMessageCountRef.current
            const difference = getDifference(a, b)
            setUserMessageCount(difference)
            setNewMessage(false)
        }
    }, [newMessage, userMessageCount]);

    // useEffect(() => {
    //     console.log(msgCountRef.current)
    //     setUserMessageCount(msgCountRef.current)
    // }, [user_message_count]);

    return (
        <ListItem style={{ backgroundColor: selectedSenderId === sender_id ? '#efdfbb' : 'transparent' }} alignItems="center" button onClick={(event) => { handleConversationClick(event, conversationIndex, sender_id, bot_channel, room_id, agent_id, conversation_tag, created_at, session_status, time_epoch, university_id); resetCounts() }}>
            <audio ref={audioPlayer} src={'https://ucsi-bot.dbix.com.my/api/get-attachment/simple_notification.mp3'} />
            <ListItemAvatar>
                <BadgeAvatars color={clock_color} />
            </ListItemAvatar>
            <ListItemText
                // primary={sender_id}
                // secondary={<Typography component={'span'} variant={'body2'}>{created_at} {conversation_tag}</Typography>}
                // secondary={<Fragment><Typography>{created_at}</Typography><Typography>{conversation_tag}</Typography></Fragment>}  
                primary={<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1' }}>
                    <p>{sender_name ? getSenderName(sender_name) : getSenderId(sender_id)}</p>
                    {conversation_tag !== 'Online' && userMessageCount > 0 && <Box style={{ display: 'flex', width: '20px', height: '20px', backgroundColor: 'rgba(2, 189, 101, 1)', borderRadius: '50%' }}><a style={{ margin: 'auto', color: 'white' }}>{userMessageCount}</a></Box>}
                </div></>}
                secondary={<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1', flexWrap: 'wrap' }}>
                    <p style={{ width: 'fit-content', wordWrap: 'break-word' }}>{created_at}</p>
                    <a style={{ minWidth: 'fit-content' }}>{conversation_tag}</a>
                </div></>}
            />
        </ListItem>

    )
}

const ConversationList = () => {
    const { limit, setLimit, chats, botChannel, selectedSenderId, setSelectedConversationTag, setSelectedSenderId, dispatch, wsRef, targetId, setSelectedRoomId, setSelectedRBotChannel, setSelectedAgentId, setSelectedCreatedAt, selectedSessionStatus, setSelectedSessionStatus, setSelectedUniversityId, filterName, filter }: any = useContext(ConversationContext)
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    // const [selectedSenderId, setSelectedSenderId] = useState('')
    // const [result, dispatch] = useReducer(ActionReducer, '')
    const [loading, setLoading]: any = useState(true)
    const [hasMore, setHasMore]: any = useState(true)
    const [endMessage, setEndMessage]: any = useState("No more conversation to load")

    const [count, setCount]: any = useState(0)

    const conversationsCount = useRef(0)

    useEffect(() => {
        setCount(0)
        setHasMore(true)
        setEndMessage("No more conversation to load")
    }, [botChannel]);

    useEffect(() => {
        if (!chats) return;
        console.log(chats.length)
        console.log(limit)
        if (chats.length < limit - 20 && hasMore) {
            console.log("adding count")
            setCount(count + 1)
        }
    }, [chats]);


    useEffect(() => {
        if (!chats) return;

        if (chats.length === 0) {
            setCount(count + 1)
            setEndMessage("No conversation to load")
        }
    }, [chats]);

    useEffect(() => {
        if (count >= 3) {
            console.log(count)
            setHasMore(false)
        }
    }, [count]);

    useEffect(() => {
        if (chats) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [chats]);

    function getHeight() {
        const height = 86;

        if (filter === true && filterName.length > 0) {
            let initialValue = 86; // Initialize the variable to the initial value
            for (let i = 0; i < filterName.length; i++) {
                initialValue -= 7; // Subtract the reduction constant from the result for each element in the array
            }
            return initialValue.toString() + "%"; // Return the final result
        } else {
            return height.toString() + "%";
        }
    }

    const handleConversationClick = (event: any, conversationIndex: any, sender_id: any, bot_channel: any, room_id: any, agent_id: any, conversation_tag: any, created_at: any, session_status: any, time_epoch: any, university_id: any) => {
        setSelectedSenderId(sender_id)
        setSelectedRoomId(room_id)
        setSelectedRBotChannel(bot_channel)
        setSelectedAgentId(agent_id)
        setSelectedConversationTag(conversation_tag)
        setSelectedCreatedAt(time_epoch)
        setSelectedSessionStatus(session_status)
        setSelectedUniversityId(university_id)
        wsRef.current.send(JSON.stringify({ action: 'listen', source_id: identity, target_id: sender_id, bot_channel: bot_channel }))
    }

    const fetchMoreData = () => {
        setTimeout(() => {
            setLimit(limit + 20)
        }, 500);
    };

    // // remove task from backend if user no longer listening to the coming target Id
    // useEffect(() => {
    //     if (!wsRef.current) return; 
    //     if (targetId && targetId !== selectedSenderId){ 
    //         wsRef.current.send(JSON.stringify({action: 'remove', source_id: identity, target_id: targetId}))
    //     }
    // }, [selectedSenderId]);

    return (
        <><div id='conversation-list'>
            {
                loading == true &&
                <Box style={{ height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                    <ReactLoading
                        type="spinningBubbles"
                        color="rgba(2, 189, 101, 1)"
                        height={100}
                        width={50}
                    />
                </Box>
            }
            <div id="scrollDiv" style={{ height: 'inherit', overflow: "auto" }}>
                <InfiniteScroll
                    dataLength={chats ? chats.length : 0}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                        <Box style={{ height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center' }}>
                            <ReactLoading
                                type="spinningBubbles"
                                color="rgba(2, 189, 101, 1)"
                                height={40}
                                width={20}
                            />
                        </Box>
                    }
                    scrollableTarget="scrollDiv"
                    endMessage={
                        <p style={{ textAlign: 'center', fontWeight: 'lighter' }}>
                            <i style={{ color: 'grey' }}>{endMessage}</i>
                        </p>
                    }
                >
                    {chats && chats.length > 0 && chats.map((chat: any) => (
                        <List dense>
                            <ConversationItem
                                key={chat.sender_id}
                                created_at={chat.created_at}
                                conversation_tag={chat.conversation_tag}
                                sender_id={chat.sender_id}
                                handleConversationClick={handleConversationClick}
                                user_message_count={chat.user_message_count}
                                room_id={chat.room_id}
                                bot_channel={chat.bot_channel}
                                agent_id={chat.agent_id}
                                clock_color={chat.clock_color}
                                session_status={chat.session_status}
                                time_epoch={chat.time_epoch}
                                university_id={chat.university_id}
                                sender_name={chat.sender_name}
                            />
                            <Divider variant="inset" component="li" />
                        </List>
                    ))
                    }

                </InfiniteScroll>
                {/* {chats && chats.length > 0 && chats.map((chat: any) => (
                        <List dense>
                            <ConversationItem
                                key={chat.sender_id}
                                created_at={chat.created_at}
                                conversation_tag={chat.conversation_tag}
                                sender_id={chat.sender_id}
                                handleConversationClick={handleConversationClick}
                                user_message_count={chat.user_message_count}
                                room_id={chat.room_id}
                                bot_channel={chat.bot_channel}
                                agent_id={chat.agent_id}
                                clock_color={chat.clock_color}
                                session_status={chat.session_status}
                                time_epoch={chat.time_epoch}
                                university_id={chat.university_id}
                                sender_name={chat.sender_name}
                            />
                            <Divider variant="inset" component="li" />
                        </List>
                    ))
                } */}
            </div>
        </div>
        </>
    )
}

export default ConversationList