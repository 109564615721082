import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
// import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useEditController, Edit, Toolbar, ShowButton,DeleteButton, SaveButton, useTranslate,TopToolbar, sanitizeListRestProps, useNotify,useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength, DateInput } from 'react-admin';
import dataProvider from '../data-provider'
import VisibilityIcon from '@mui/icons-material/Visibility';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))

/**
 * Async function to fetch User Role list
 * 
 * @param {*} notify 
 */

const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const navigate = useNavigate()
        return (
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                </TopToolbar>
            
        )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({...props}) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate(`/live-agent-holiday`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton /> 
        </Toolbar>
    )
}


const HolidayEdit = ({...props}) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [roleChoices, setRoleChoices] = React.useState([])
    const validateResponseName = [
        required(translate('resources.holiday.errors.date_of_holiday.required'))
    ]
    const validateLanguage = [required(translate('resources.holiday.errors.description.required'))
    ]
    // const validateRemark = [
    //     required(translate('resources.holiday.errors.remark.required'))
    // ]
    // Initial the Role choices only once
    // useEffect( () => {
    //     fetchRoleChoices(notify).then((choices) => setRoleChoices(choices))
    // }, [])

    /**
     * Edit Form submission failure handling
     * @param {} param0 
     */
    // const onEditFailure = (error: any) => {
    //     if (typeof error === 'string') {
    //         notify(error)
    //     } else if (error.status == 400) {
    //         for (var key of Object.keys(error.body.messages)) {
    //             error.body.messages[key].forEach(msg=> notify(msg))
    //         }
    //     } else if (error.message)
    //         notify(error.message)
    //     else
    //         notify('ra.notification.http_error')
            
    // }

    const onEditSuccess = ({ data }: any) => {
        notify(`Holiday "${data.target_name}" successfully updated`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        delete data['id']
        // data.bot_response = data.bot_response.id  // Transform role attribute to store role_id because backend expect role id.
        return {
            ...data
        }
    }

    const validateDescription = [required(translate('resources.holiday.errors.description.required')),
                minLength(5, translate('resources.holiday.errors.description.minLength')),
                maxLength(100, translate('resources.holiday.errors.description.maxLength'))]
                
    return (
        <Edit title={translate('resources.holiday.form.edit')} 
            // onFailure={onEditFailure}
            // onSuccess={onEditSuccess}
            transform={transformRecord}
            actions={<ListActions />}
            // undoable={false}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar/>}  >
                <br/>
                <DateInput
                    label={translate('resources.holiday.fields.date_of_holiday')}  source="date_of_holiday"/>
                <TextInput variant="outlined" source="description"
                    validate={validateDescription}
                    resettable={true}
                    label={translate('resources.holiday.fields.description')} 
                    helperText={translate('resources.holiday.helperText.description')} 
                    fullWidth/>
            </SimpleForm>
        </Edit>
    )
}

export default HolidayEdit