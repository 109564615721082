import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
// import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useEditController, Edit, Toolbar, ShowButton,DeleteButton, SaveButton, useTranslate,TopToolbar, sanitizeListRestProps, useNotify,useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength } from 'react-admin';
import dataProvider from '../data-provider'
import VisibilityIcon from '@mui/icons-material/Visibility';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))

/**
 * Async function to fetch User Role list
 * 
 * @param {*} notify 
 */

const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const navigate = useNavigate()
        return (
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                </TopToolbar>
            
        )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({...props}) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine}/>
            <Button variant="contained" onClick={() => navigate(`/contacts`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton /> 
        </Toolbar>
    )
}


const ContactEdit = ({...props}) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [roleChoices, setRoleChoices] = React.useState([])


    /**
     * Edit Form submission failure handling
     * @param {} param0 
     */
    // const onEditFailure = (error: any) => {
    //     if (typeof error === 'string') {
    //         notify(error)
    //     } else if (error.status == 400) {
    //         for (var key of Object.keys(error.body.messages)) {
    //             error.body.messages[key].forEach(msg=> notify(msg))
    //         }
    //     } else if (error.message)
    //         notify(error.message)
    //     else
    //         notify('ra.notification.http_error')
            
    // }

    const onEditSuccess = ({ data }: any) => {
        notify(`Bot Response "${data.target_name}" successfully updated`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        delete data['id']
        // data.bot_response = data.bot_response.id  // Transform role attribute to store role_id because backend expect role id.
        return {
            ...data
        }
    }
    return (
        <Edit title={translate('resources.contacts.form.edit')} 
            // onFailure={onEditFailure}
            // onSuccess={onEditSuccess}
            transform={transformRecord}
            actions={<ListActions />}
            // undoable={false}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar/>}  >
                <br/>
                <SelectInput variant="outlined" source="salutation"
                    resettable={true}
                    label={translate('resources.contacts.fields.salutation')} 
                    helperText={translate('resources.contacts.helperText.salutation')} 
                    fullWidth
                    choices={[
                        { id: 'Mr.', name: 'Mr.'},
                        { id: 'Mrs.', name: 'Mrs.'},
                        { id: 'Ms.', name: 'Ms.'},
                    ]}/>
                <TextInput variant="outlined" source="name"
                    resettable={true}
                    label={translate('resources.contacts.fields.name')} 
                    helperText={translate('resources.contacts.helperText.name')} 
                    fullWidth/>
                <TextInput variant="outlined" source="nric"
                    resettable={true}
                    label={translate('resources.contacts.fields.nric')} 
                    helperText={translate('resources.contacts.helperText.nric')} 
                    fullWidth/>
                <TextInput variant="outlined" source="contact_number"
                    resettable={true}
                    label={translate('resources.contacts.fields.contact_number')} 
                    helperText={translate('resources.contacts.helperText.contact_number')} 
                    fullWidth multiline/>
                <TextInput variant="outlined" source="email"
                    resettable={true}
                    label={translate('resources.contacts.fields.email')} 
                    helperText={translate('resources.contacts.helperText.email')} 
                    fullWidth/>
                <SelectInput variant="outlined" source="gender"
                    resettable={true}
                    label={translate('resources.contacts.fields.gender')} 
                    helperText={translate('resources.contacts.helperText.gender')} 
                    fullWidth
                    choices={[
                        { id: 'M', name: 'Male'},
                        { id: 'F.', name: 'Female'}
                    ]}/>
                <SelectInput variant="outlined" source="marital"
                    resettable={true}
                    label={translate('resources.contacts.fields.marital')} 
                    helperText={translate('resources.contacts.helperText.marital')} 
                    fullWidth
                    choices={[
                        { id: 'Married', name: 'Married'},
                        { id: 'Widowed', name: 'Widowed'},
                        { id: 'Separated', name: 'Separated'},
                        { id: 'Divorced', name: 'Divorced'},
                        { id: 'Single', name: 'Single'},
                    ]}/>
                <TextInput variant="outlined" source="dob"
                    resettable={true}
                    label={translate('resources.contacts.fields.dob')} 
                    helperText={translate('resources.contacts.helperText.dob')} 
                    fullWidth/>
                <TextInput variant="outlined" source="address"
                    resettable={true}
                    label={translate('resources.contacts.fields.address')} 
                    helperText={translate('resources.contacts.helperText.address')} 
                    fullWidth multiline/>
                <TextInput variant="outlined" source="remark"
                    resettable={true}
                    label={translate('resources.contacts.fields.remark')} 
                    helperText={translate('resources.contacts.helperText.remark')} 
                    fullWidth/>
            </SimpleForm>
        </Edit>
    )
}

export default ContactEdit