import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';

import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength } from 'react-admin';
import dataProvider from '../data-provider'

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({basePath}: any, {...props}) => {
    const navigate = useNavigate()
    // const classes = useStyles()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate('/intent')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const IntentCreate = (props: any) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();

    /**
     * Create Form submission failure handling
     * @param {} param0 
     */

    const onSuccess = ({ data }: any) => {
        notify(`Bot Intent "${data.name}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
      };

    const onCreateFailure = (error: any) => {
        if (typeof error === 'string') {
            notify(error)
        } else if (error.message)
            notify(error.message)
        else
            notify('ra.notification.http_error')
            redirect('/intent');
            refresh();
    }

    const validateIntentName = [
        required(translate('resources.intent.errors.name.required'))
    ]
    const validateLanguage = [required(translate('resources.intent.errors.language.required'))
    ]
    const validateChannel = [
        required(translate('resources.intent.errors.input_channel.required'))
    ]
    const validatePhrases = [
        required(translate('resources.intent.errors.phrases.required'))
    ]

    
    return (
        <Create title={translate('resources.intent.form.create')} onSuccess={onSuccess} onFailure={onCreateFailure}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />}  >
                <br/>
                <TextInput variant="outlined" source="name"
                    validate={validateIntentName}
                    resettable={true}
                    label={translate('resources.intent.fields.name')} 
                    helperText={translate('resources.intent.helperText.name')} 
                    fullWidth/>
                <SelectInput variant="outlined" source="language"
                    validate={validateLanguage}
                    resettable={true}
                    label={translate('resources.intent.fields.language')} 
                    helperText={translate('resources.intent.helperText.language')} 
                    choices={[
                        {id: "en", name: "English"}, 
                    ]}/>
                <TextInput variant="outlined" source="phrases"
                    validate={validatePhrases}
                    resettable={true}
                    label={translate('resources.intent.fields.phrases')} 
                    helperText={translate('resources.intent.helperText.phrases')} 
                    fullWidth multiline/>
                <SelectInput variant="outlined" source="input_channel"
                    validate={validateChannel}
                    resettable={true}
                    label={translate('resources.intent.fields.input_channel')} 
                    helperText={translate('resources.intent.helperText.input_channel')} 
                    choices={[
                        {id: "wa", name: "WhatsApp"}, 
                        {id: 'fb', name: "Facebook"},
                        {id: "webchat", name: "Webchat"}
                    ]}/>
            </SimpleForm>
        </Create>
    )
}

export default IntentCreate