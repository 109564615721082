import ContactList from './contact-list'
import ContactCreate from './contact-create'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactShow from './contact-show'
import ContactEdit from './contact-edit';

export default {
    create: ContactCreate,
    edit: ContactEdit,
    list: ContactList,
    show: ContactShow,
    icon: ContactPhoneIcon,
}