import * as React from "react";
import { Show, EditButton, SingleFieldList, Toolbar, SimpleForm, Datagrid, TextInput, ArrayInput, SimpleFormIterator, useTranslate, TopToolbar, sanitizeListRestProps, BooleanInput, SelectArrayInput, FileField, SelectInput, DateField, ArrayField, TabbedShowLayout, Tab, FormDataConsumer, useRedirect } from 'react-admin';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Button, Table, TableBody, TableHead, TableCell, TableRow, Paper, TableContainer, Grid, InputLabel, OutlinedInput } from '@mui/material';
import dataProvider from '../data-provider'
import { useNavigate, useParams } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { APP_PERMISSIONS } from '../constants';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';

import { BACKEND_URL } from '../constants';

const ListActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props

  const history = useNavigate()
  return (
    <div>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      </TopToolbar>
    </div>

  )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ permissions, ...props }: any) => {
  const redirect = useRedirect()

  return (
    <Toolbar {...props} >
      {permissions ? permissions.includes(APP_PERMISSIONS.MARKETING.WRITE) &&
        <EditButton record={props.data} /> : ""}
      <Button variant="contained" onClick={() => redirect('/promotions')} color="inherit" startIcon={<ArrowBackIcon />}>
        BACK
      </Button>
    </Toolbar>
  )
}

const PromotionShow = (props: any) => {
  const translate = useTranslate()
  const [showPastSchedule, setShowPastSchedule] = React.useState(false);
  const [customerList, setCustomerList]: any[] = React.useState([]);
  const [activeBroadcastDates, setActiveBroadcastDates]: any[] = React.useState([]);
  const [nonActiveBroadcastDates, setNonActiveBroadcastDates]: any[] = React.useState([]);
  const [broadcastLog, setBroadcastLog]: any[] = React.useState(null);
  const [broadcastList, setBroadcastList]: any[] = React.useState(null);
  const [broadcastId, setBroadcastId]: any[] = React.useState('');

  const [templateName, setTemplateName]: any[] = React.useState('');

  const [isViewMessage, setViewMessage] = React.useState(false);

  const [hasContent, setHasContent] = React.useState(false);
  const [hasHeaderContent, setHasHeaderContent] = React.useState(false);
  // const [hasFooter, setHasFooter] = React.useState(false);
  const [hasButton, setHasButton] = React.useState(false);
  const [bodyContent, setBodyContent] = React.useState("");
  const [headerContent, setHeaderContent] = React.useState("");
  const [buttonContent, setButtonContent] = React.useState([]);
  const [contentVar, setContentVar] = React.useState([]);

  const params = useParams();
  const access_token = sessionStorage.getItem('token');
  // const [footerContent, setFooterContent] = React.useState("");

  const handleExport = async () => {
    const apiUrl = `${BACKEND_URL}/download-broadcast-log/${broadcastId}`;

    fetch(apiUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Authorization": `Bearer ${access_token}`
        }
    }).then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'broadcast-log.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
  }

  const openDialog = () => {
    setViewMessage(true);
  };

  const closeDialog = () => {
    setViewMessage(false);
  };


  React.useEffect(() => {
    console.log(props)
    console.log('props.id', props.id)
    dataProvider.getOne('fetch-promotion', { id: params.id })
      .then(({ data }) => {
        console.log(data)
        setShowPastSchedule(data.show_past_dates)
        setCustomerList(data.customer_list)
        setActiveBroadcastDates(data.active_marketing_promotion_schedules)
        setNonActiveBroadcastDates(data.non_active_marketing_promotion_schedules)
        setTemplateName(data.template_name)
        if (data.non_active_marketing_promotion_schedules != null) {
          setBroadcastLog(data.non_active_marketing_promotion_schedules[0].broadcast_log)
          setBroadcastList(data.non_active_marketing_promotion_schedules[0].broadcast_list)
          setBroadcastId(data.non_active_marketing_promotion_schedules[0].broadcast_id)
        }

        if (data.content != null) {
          setHasContent(true)
          setBodyContent(data.content)
          setContentVar(data.content_var)
        }

        if (data.header_content != null) {
          setHasHeaderContent(data.has_header)
          setHeaderContent(data.header_content)
        }
      })
  }, []);

  React.useEffect(() => {
    console.log(props)
    console.log('props.id', props.id)
    dataProvider.getOne('fetch-promotion', { id: params.id })
      .then(({ data }) => {
        console.log(data)
        setShowPastSchedule(data.show_past_dates)
        setCustomerList(data.customer_list)
        setActiveBroadcastDates(data.active_marketing_promotion_schedules)
        setNonActiveBroadcastDates(data.non_active_marketing_promotion_schedules)
        if (data.content != null) {
          setHasContent(true)
          setBodyContent(data.content)
        }
        if (data.header_content != null) {
          setHasHeaderContent(true)
          setHeaderContent(data.header_content)
        }

        // if (data.footer_content != null){
        //   setHasFooter(true)
        //   setFooterContent(data.footer_content)
        // } 

        if (data.button_content != null) {
          setHasButton(true)
          setButtonContent(data.button_content)
        }
      })
      .catch(error => {
        // setLoading(false);
      })
  }, []);

  const CustomizedTables = ({ }) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="left">Customer Name</TableCell>
              <TableCell align="left">Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerList.map((row: any, index: any) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const CustomizedDatesDisplayer = ({ }) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized date display" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="left">Broadcast Date</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nonActiveBroadcastDates.map((row: any, index: any) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.broadcast_date}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
              </TableRow>
            ))}
            {activeBroadcastDates.map((row: any, index: any) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.broadcast_date}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const ShowHeaderParameters = (record: any) => {
    if (record.header_type == 2 || record.header_type == 3 || record.header_type == 4) {

      return (
        <div>
          <div>
            <br />
            <InputLabel>Header</InputLabel>
            <hr />
          </div>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <a href={'' + record.attachment} title="Attachment" download>Attachment</a>
            </Grid>
          </Grid>
        </div>
      )

    } else if (record.header_type == 1) {
      if (record.header_content != null && record.header_content != "null") {
        var params = JSON.parse(record.header_content)
        for (var i = 0; i < params.length; i++) {
          params[i]['id'] = i
        }
        record.header_parameters = params

        return (
          <ArrayInput source="header_parameters">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) =>
                  scopedFormData ? (
                    <div>
                      <br />
                      <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''} disabled fullWidth />
                    </div>

                  )
                    : null
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>)
      }
      else {
        return (
          <span></span>
        )

      }
    }
    else {
      return (
        <span></span>
      )

    }
  };

  const ShowBodyParameters = (record: any) => {
    var hasParams = false
    if (record.body_content != null && record.body_content != "null") {
      hasParams = true
      var params = JSON.parse(record.body_content)
      for (var i = 0; i < params.length; i++) {
        params[i]['id'] = i
      }

      record.content_parameters = params
      return (
        <ArrayInput source="content_parameters">
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest

              }) =>

                scopedFormData ? (
                  <div>
                    <br />
                    <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''} multiline disabled fullWidth />
                  </div>

                )
                  : null
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )

    } else {
      return (
        <span></span>
      )

    }
  };

  // const ShowFooterParameters = ({ record }) => {
  //   var hasParams = false
  //   if (record.footer_content != null && record.footer_content != "null" ) {
  //     hasParams = true
  //     var fparams = JSON.parse(record.footer_content)
  //     for (var i = 0; i < fparams.length; i++) {
  //       fparams[i]['id'] = i
  //     }

  //     record.footer_parameters = fparams  
  //     return(
  //       <ArrayInput source="footer_parameters">
  //         <SimpleFormIterator disableAdd disableRemove>
  //           <FormDataConsumer>
  //                   {({
  //                       formData, // The whole form data
  //                       scopedFormData, // The data for this item of the ArrayInput
  //                       getSource, // A function to get the valid source inside an ArrayInput
  //                       ...rest

  //                   }) => 

  //                     scopedFormData ? (
  //                       <div>
  //                         <br/>
  //                         <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource('value')}  disabled fullWidth/>
  //                       </div>

  //                     )
  //                     :null
  //                   }
  //             </FormDataConsumer>
  //           </SimpleFormIterator>
  //       </ArrayInput>
  //   )

  //   }else{
  //     return(
  //       <span></span>
  //     )

  //   }
  // };

  const ShowButtonParameters = (record: any) => {
    if (record.button_content != null && record.button_content != "null") {
      var bparams = JSON.parse(record.button_content)
      for (var i = 0; i < bparams.length; i++) {
        bparams[i]['id'] = i
      }

      record.button_parameters = bparams
      return (
        <ArrayInput source="button_parameters">
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest

              }) =>

                scopedFormData ? (
                  <div>
                    <br />
                    <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''} disabled fullWidth />
                  </div>

                )
                  : null
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )

    } else {
      return (
        <span></span>
      )

    }
  };

  const BlueDoneAllIcon = () => {
    return <DoneAllIcon style={{ color: 'blue' }} />;
  };

  const RedErrorIcon = () => {
    return <ErrorIcon style={{ color: 'red' }} />;
  };

  interface IconTextProps {
    icon: React.ReactElement;
    text: string;
  }

  const IconText = ({ icon, text }: IconTextProps) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <h1 style={{ marginLeft: '5px' }}>{text}</h1>
    </div>
  );

  const CampaignShowTitle = (record: any) => {
    return <span>{translate('resources.promotions.name')} [{record.broadcast_name}]</span>
  };

  // return (
  //   <Show {...props} title={<CampaignShowTitle />}
  //     actions={<ListActions />} resource="promotion">
  //     <TabbedShowLayout>
  //       <Tab label="Promotion">
  //         <SimpleForm toolbar={<BottomToolbar permissions={sessionStorage.getItem('permissions')} />}>
  //           <TextInput variant="outlined" source="broadcast_name" disabled fullWidth />
  //           <TextInput variant="outlined" source="template_name" disabled fullWidth />
  //         </SimpleForm>
  //       </Tab>
  //     </TabbedShowLayout>
  //   </Show>
  // );

  // const BlueDoneAllIcon = () => {
  //   return <DoneAllIcon style={{ color: 'blue' }} />;
  // };

  // const RedErrorIcon = () => {
  //   return <ErrorIcon style={{ color: 'red' }} />;
  // };

  // interface IconTextProps {
  //   icon: React.ReactElement;
  //   text: string;
  // }

  // const IconText = ({ icon, text }: IconTextProps) => (
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //     {icon}
  //     <h1 style={{ marginLeft: '5px'}}>{text}</h1>
  //   </div>
  // );

  // {
  //   hasHeaderContent ?
  //     <div>
  //       <br />
  //       <InputLabel>Header</InputLabel>
  //       <hr />
  //     </div>
  //     : ''
  // }

  const formatBodyContent = (text: string) => {
    // Replace *bold*
    text = text.replace(/\*([^*]+)\*/g, '<b>$1</b>');

    // Replace _italic_
    text = text.replace(/_([^_]+)_/g, '<i>$1</i>');

    // Replace ~strike~
    text = text.replace(/~([^~]+)~/g, '<s>$1</s>');

    console.log(contentVar)
    console.log(text)
    if (contentVar.length > 0) {
      contentVar.forEach((variable: any) => {
        console.log(variable)
        text = text.replace(`{{${variable.name}}}`, variable.value);
      });
    }
    console.log(text)

    return text;
  };

  return (
    <Show {...props} title={<CampaignShowTitle />}
      actions={<ListActions />} resource="promotion">
      <TabbedShowLayout>
        <Tab label="Promotion">
          <SimpleForm toolbar={<BottomToolbar permissions={sessionStorage.getItem('permissions')} />}>
            <TextInput variant="outlined" source="broadcast_name" disabled fullWidth />
            <TextInput variant="outlined" source="template_name" disabled fullWidth />

            <ShowHeaderParameters></ShowHeaderParameters>

            {hasContent ?
              <div>
                <br />
                <InputLabel>Body</InputLabel>
                <hr />
              </div>
              : ''}

            {hasHeaderContent ? (
              <Box alignItems="center" borderRadius={1} p={1} maxWidth='65vh'>
                {/* Check the file type and render accordingly */}
                {headerContent.endsWith('.pdf') ? (
                  <iframe src={`https://docs.google.com/gview?url=${headerContent}&embedded=true`} style={{ width: '100%', height: '500px', border: 'none' }}></iframe>
                ) : headerContent.endsWith('.doc') || headerContent.endsWith('.docx') ? (
                  <embed src={headerContent} type="application/msword" style={{ width: '100%', height: '500px' }} />
                ) : headerContent.endsWith('.mp4') || headerContent.endsWith('.avi') || headerContent.endsWith('.mov') ? (
                  <video controls style={{ width: '100%' }}>
                    <source src={headerContent} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={headerContent} alt="Custom Image" style={{ maxWidth: '100%' }} />
                )}
              </Box>
            ) : ''}

            {/* { hasFooter ? 
                      <div className={classes.outlinedText}>
                        <br/>
                        <InputLabel>Footer</InputLabel>
                        <hr/> 
                      </div>
                  : ''}

                { hasFooter ? 
                    <OutlinedInput
                    fullWidth={true}
                    id="outlined-header-text"
                    value={footerContent}
                    multiline={true}
                    disabled/>
                  : ''}

                <ShowFooterParameters></ShowFooterParameters> */}


            {hasButton ?
              <div>
                <br />
                <InputLabel>Buttons</InputLabel>
                <hr />
              </div>
              : ''}

            {hasButton ? buttonContent.map((row: any, index: any) => (
              <OutlinedInput
                fullWidth={true}
                id="outlined-header-text"
                value={row.value}
                multiline={true}
                disabled
              />

            ))

              : ''}

            <ShowButtonParameters></ShowButtonParameters>


            <br />
            <CustomizedTables />
            <br />
          </SimpleForm>
        </Tab>
        <Tab label="Broadcast Schedule">
          <SimpleForm toolbar={<BottomToolbar />}>
            {/* { showPastSchedule ?
                      <ArrayField source="non_active_marketing_promotion_schedules">
                        <Datagrid>
                            <DateField source="broadcast_date" showTime/>
                        </Datagrid>
                      </ArrayField>
                : '' } */}

            <CustomizedDatesDisplayer />
          </SimpleForm>
        </Tab>

        <Tab label="Result">
          <SimpleForm toolbar={<BottomToolbar />}>
            <Button
              color="success"
              variant="contained"
              sx={{ fontSize: '14px', width: '20%', padding: '8px 16px', margin: '8px' }}
              onClick={handleExport}
            >
              Download
            </Button>
            <p><b>Status:</b> Completed</p>
            <p><b>Template Name:</b> {templateName}</p>
            <p><b>Sending Time:</b> {broadcastList && broadcastList.length > 0 ? broadcastList[0].created_at : ""}</p>

            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Read</TableCell>
                    <TableCell>Delivered</TableCell>
                    <TableCell>Sent</TableCell>
                    <TableCell>Pending</TableCell>
                    <TableCell>Failed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <IconText
                        icon={<BlueDoneAllIcon />}
                        text={broadcastLog && broadcastLog.read ? broadcastLog.read : "0"}
                      />
                    </TableCell>
                    <TableCell>
                      <IconText
                        icon={<DoneAllIcon />}
                        text={broadcastLog && broadcastLog.delivered ? broadcastLog.delivered : "0"}
                      />
                    </TableCell>
                    <TableCell>
                      <IconText
                        icon={<CheckIcon />}
                        text={broadcastLog && broadcastLog.sent ? broadcastLog.sent : "0"}
                      />
                    </TableCell>
                    <TableCell>
                      <IconText
                        icon={<HourglassEmptyIcon />}
                        text={broadcastLog && broadcastLog.pending ? broadcastLog.pending : "0"}
                      />
                    </TableCell>
                    <TableCell>
                      <IconText
                        icon={<RedErrorIcon />}
                        text={broadcastLog && broadcastLog.failed ? broadcastLog.failed : "0"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer><br />

            <TableContainer component={Paper}>
              <Table aria-label="customized table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="left">Customer Number</TableCell>
                    <TableCell align="left">Creation Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {broadcastList && broadcastList.map((row: any, index: any) => (
                    <TableRow key={row.contact}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.contact}</TableCell>
                      <TableCell align="left">{row.created_at}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="center"><Button variant="contained" color="primary" onClick={openDialog} size="small">View Message</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>

      <Dialog open={isViewMessage} onClose={closeDialog}>
        <Stack display="flex" flexDirection="row" justifyContent="space-between">
          <DialogTitle>Message Preview</DialogTitle>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              x
            </Button>
          </DialogActions>
        </Stack>
        <DialogContent style={{
          background: 'url("https://w0.peakpx.com/wallpaper/818/148/HD-wallpaper-whatsapp-background-cool-dark-green-new-theme-whatsapp.jpg")'
        }}>
          <Box display="flex" flexDirection="column" borderRadius={1} p={3} alignItems="center" boxShadow={2} bgcolor="#ffffff" color="#000000" whiteSpace='pre-wrap' maxWidth='60%' flexWrap='wrap'>

            {
              hasHeaderContent && (
                <Box alignItems="center" borderRadius={1} p={1} maxWidth='65vh'>
                  {headerContent.endsWith('.pdf') ? (
                    <div style={{ background: 'rgba(192, 192, 192, 0.5)', padding: '10px', borderRadius: '5px' }}>
                      <p style={{ margin: '0', color: '#333' }}>Content type not supported in Message Preview at the moment.</p>
                    </div>
                  ) : headerContent.endsWith('.doc') || headerContent.endsWith('.docx') ? (
                    <div style={{ background: 'rgba(192, 192, 192, 0.5)', padding: '10px', borderRadius: '5px' }}>
                      <p style={{ margin: '0', color: '#333' }}>Content type not supported in Message Preview at the moment.</p>
                    </div>
                  ) : headerContent.endsWith('.mp4') || headerContent.endsWith('.avi') || headerContent.endsWith('.mov') ? (
                    <div style={{ background: 'rgba(192, 192, 192, 0.5)', padding: '10px', borderRadius: '5px' }}>
                      <p style={{ margin: '0', color: '#333' }}>Content type not supported in Message Preview at the moment.</p>
                    </div>
                  ) : (
                    <img src={headerContent} alt="Custom Image" style={{ maxWidth: '100%' }} />
                  )}
                </Box>
              )}

            {
              hasContent && (
                <Box borderRadius={1} p={1} maxWidth='65vh' whiteSpace='pre-wrap' style={{
                  // background: 'white',
                  // color: 'black',
                  // padding: '10px',
                  // borderRadius:  hasButton ? '10px 10px 0 0' : '10px',
                  // borderBottom:  hasButton ? '1px solid rgb(189, 189, 189)' : '0',
                  // maxWidth: '80%',
                  // position: 'relative',
                  // margin: '0 10px 0 10px',
                }}
                  dangerouslySetInnerHTML={{ __html: formatBodyContent(bodyContent) }}
                />
              )}

            {
              hasButton && buttonContent.map((row: any, index: any) => (
                <>
                  <Box width="100%" style={{ border: '1px solid rgb(189, 189, 189)' }}></Box>
                  <Box alignItems="center" borderRadius={1} p={1} style={{
                    // background: 'white',
                    color: '#009dff',
                    // padding: '10px',
                    // borderRadius: hasButton ? '0' : '0 0 10px 10px',
                    // maxWidth: '80%',
                    // position: 'relative',
                    // textAlign: 'center',
                    // margin: '0 10px 0 10px',
                  }}


                    dangerouslySetInnerHTML={{ __html: row.value }}
                  />
                </>

              ))

            }
          </Box>
        </DialogContent>
      </Dialog>

    </Show>
  )
}
export default PromotionShow