import { useState, useEffect }  from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLegend } from "victory";
import { BACKEND_URL } from '../../constants'
import { OutlinedInput, Badge, Stack, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function StudentCompositionChart({selectedStartDate, selectedEndDate}: any) {
  const [basis, setBasis] = useState(10);
  const [chartData, setChartData] = useState([
    {
      "country": "Malaysia",
      "data": [
          {
              "category": "Bachelors",
              "count": 1
          }
      ],
      "backgroundColor": [
          "#009688"
      ]
    }
  ]);

  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-student-nationality-and-completion-level?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setChartData(data);
        console.log(data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);


  const names = ['SPM','STPM','SKM', 'UEC', 'Senior Middle 2 (SM2)', 'IGCSE / O-Level', 'Foundation/Pre U', 'Diploma', 'Bachelor Degree', 'Certificate', 'A-Level', 'Master Degree', 'Government Matriculation', 'MUFY', 'AUSMAT', 'Victorian Certificate of Education', 'HSC/ Grade 12', 'International Baccalaureate', 'APEL', 'Others', 'HSC Saudi Arabia', 'UAE Secondary School Certificate - Grade 12'];

  const [personName, setPersonName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
        <Stack alignItems='center' direction="row">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="h5">Students' Nationality and Highest Qualification
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Qualifications</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput label="Qualifications" />}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <br/>
      <br/>
      <Box>
        <Stack flexDirection="row" alignItems="flex-end" justifyContent="space-around" flexWrap="wrap">
          {chartData.map((countryData) => (
            <Box>
              <Box width={250} height={250}>
                <Pie 
                  data={{
                  labels: countryData?.data.map((item) => item.category),
                  datasets: [
                    {
                      data: countryData?.data.map((item) => item.count),
                      backgroundColor: countryData.backgroundColor,
                    }
                  ]
                }}/>
              </Box>
              <br/>
              <Typography align='center' variant="body1">{countryData.country}</Typography>
            </Box>
          ))}
        </Stack>
      </Box>
      <br/>
      <br/>

    </Paper>
  );
};


