import { 
    useTranslate, 
    TextInput, 
    ShowButton, 
    FilterButton,
    useListContext, 
    TopToolbar, 
    usePermissions,
    List, 
    Datagrid, 
    TextField, 
    RichTextField, 
    Pagination, 
    downloadCSV, 
    useRedirect, 
    FunctionField, 
    CreateButton
} from 'react-admin'
import Button from '@mui/material/Button';
import {cloneElement} from 'react'
import { useNavigate } from 'react-router-dom' 
import { CustomDateField, CustomDateTimePicker } from '../custom-components'
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
// import IconButton from '@material-ui/core/IconButton'
// import { sendTemplateMessage } from '../redux/actions'
// import { template_message } from '../redux/store'
// import Icon from '@material-ui/core/Icon'

// Filters for the Contact listing
const ContactFilter = [
    <TextInput source="name"/>,
    <TextInput source="nric"/>,
    <TextInput source="contact_number"/>,
    <TextInput source="email"/>
]

// Paginator for the Contact listing
const ContactPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({...props}) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext()

    const navigate = useNavigate()
        return (
            <div>
            <TopToolbar>
            <FilterButton/>
            {      
            <Button variant="text" onClick={() => navigate('/contacts/create')} startIcon={<AddIcon />}>
                Import Contacts
            </Button>
            }
            {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}   
            </TopToolbar>
            </div>
        )
}


const ContactList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;
    return (
        <List actions={<ListActions permissions={permissions}/>} filters={ContactFilter} title={translate('resources.contacts.form.list')} bulkActionButtons={false} exporter={false} pagination={<ContactPaginator/>}>
            <Datagrid>
                <TextField label="id" source="id"/>
                <TextField label={translate('resources.contacts.fields.name')} source="name"/>
                <TextField label={translate('resources.contacts.fields.nric')} source="nric"/>
                <TextField label={translate('resources.contacts.fields.contact_number')} source="contact_number"/>
                <TextField label={translate('resources.contacts.fields.email')} source="email"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default ContactList