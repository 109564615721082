import {
    useEditController,
    useNotify,
    useRedirect,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    CheckboxGroupInput,
    usePermissions,
    SaveButton,
    DeleteButton,
    useRefresh,
    Edit
} from 'react-admin';

import Button from '@mui/material/Button';
import { useState, cloneElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dataProvider from '../data-provider'
import { APP_PERMISSIONS } from '../constants'


/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({...props}) => {
    const { permissions } = usePermissions();

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            {/* <Button variant="contained" onClick={() => history.push(basePath)} color="default" className={classes.button} startIcon={<CancelIcon />}>
                Cancel
            </Button> */}
            {permissions ? permissions.includes('ROLE:DELETE') && <DeleteButton /> : ""} 
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

        return (
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                </TopToolbar>
            
        )
}

const RoleEdit = ({...props}) => {
    const translate = useTranslate()
    const notify = useNotify()
    const { permissions } = usePermissions();
    const [permissionChoices, setPermissionChoices]: any[] = useState([])
    const refresh = useRefresh();
    const redirect = useRedirect();

    // Initial the Role choices only once
    useEffect(() => {
        dataProvider.getMany('fetch-permission-choices', { ids: [] })
            .then(({ data }) => {
                setPermissionChoices(data);
            })
            .catch(err => {
                notify(err.message)
            })
    }, []);

    const onEditSuccess = (data: any) => {
        notify(`Role "${data.target_name}" successfully updated`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    /**
     * Edit Form submission failure handling
     * @param {} param0 
     */
    // const onEditFailure = (error: any) => {
    //     if (typeof error === 'string') {
    //         notify(error, 'warning')
    //     } else if (error.status == 400) {
    //         for (var key of Object.keys(error.body.messages)) {
    //             error.body.messages[key].forEach( msg: any => notify(msg, 'warning'))
    //         }
    //     } else if (error.message)
    //         notify(error.message, 'warning')
    //     else
    //         notify('ra.notification.http_error', 'warning')
            
    // }

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        delete data['id']
        // data.role = data.role.id  // Transform role attribute to store role_id because backend expect role id.
        return {
            ...data
        }
    }
    return (
        <Edit title={translate('resources.user.form.edit')} 
            // onSuccess={onEditSuccess}
            // onFailure={onEditFailure}
            transform={transformRecord}
            // undoable={false}
            actions={<ListActions />}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}  >
            <br/>
                <BooleanInput variant="outlined" source="enabled"
                    label={translate('resources.role.fields.enabled')}
                    helperText={translate('resources.role.helperText.enabled')}
                    fullWidth/>
                <br/>
                <TextInput variant="outlined" source="role_name"
                    resettable={true}
                    label={translate('resources.role.fields.name')} 
                    helperText={translate('resources.role.helperText.name')} 
                    fullWidth/>

                {permissionChoices.map(function(object: any, index: any){
                    return    <CheckboxGroupInput key={index} label={object.module} source="permissions" choices={object.permissions} optionValue="id" optionText="name"/>
                })}

            </SimpleForm>
        </Edit>
    )
}

export default RoleEdit