import React, { useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength, TabbedForm, FormTab, FileInput, FileField, ArrayInput, SimpleFormIterator, DateTimeInput, SimpleList } from 'react-admin';
import dataProvider from '../data-provider'
import botResponse from "."
import { BACKEND_URL } from '../constants'
import { InputLabel, OutlinedInput } from '@mui/material';
import axios from 'axios';
import GetAppIcon from '@mui/icons-material/GetApp';
import moment from 'moment';

const BottomToolbar = ({ saveButtonClicked, ...props }: any) => {
    const redirect = useRedirect();

    return (
        <Toolbar >
            <SaveButton onClick={props.saveButtonClick} />
            <Button variant="contained" onClick={() => redirect('/promotions')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const validateTitle = [required(), minLength(2), maxLength(255)];


const dateValidation = (value: any, allValues: any) => {
    if (!value) {
        return 'Date is required';
    }
    if (value <= new Date()) {
        return 'Must be after today ';
    }
    return [];
};

// const contentValidation = (value, allValues) => {
//     return [];
// };


const validateDate = [required(), dateValidation];
const validateHeaderFooter = [required(), maxLength(60)];
const validateContent = [required()];


const PromotionCreate = (props: any) => {
    const translate = useTranslate()
    const [disableAddDate, setDisableAddDate] = React.useState(false);
    const [bodyParams, setBodyParams] = React.useState([]);
    const [hasBodyParams, setHasBodyParams] = React.useState(false);
    const [templateList, setTemplateList]: any[] = React.useState([]);
    const [headerParams, setHeaderParams] = React.useState([]);
    const [hasHeaderParams, setHasHeaderParams] = React.useState(false);
    const [hasAttachment, setHasAttachment] = React.useState(false);
    const [hasHeader, setHasHeader] = React.useState(false);
    const [fileType, setFileType] = React.useState("");
    const [placeholderAttachment, setPlaceholderAttachment] = React.useState("");
    const [contentText, setContentText] = React.useState("");
    const [headerText, setHeaderText] = React.useState("");
    const [hasBody, setHasBody] = React.useState(false);
    const [hasButton, setHasButton] = React.useState(false);
    const [btnText, setBtnText] = React.useState("");
    const [btnParams, setBtnParams] = React.useState([]);

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    // const fetchTemplate = async () => {
    //     const response = await fetch(`${BACKEND_URL}/fetch-templates`, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //     const data = await response.json();
    //     console.log(data)
    //     setTemplateList(JSON.parse(data));
    // }

    React.useEffect(() => {
        dataProvider.getMany('fetch-templates', { ids: [] })
            .then(({ data }: any) => {
                console.log(data)
                setTemplateList(JSON.parse(data));
            })
            .catch(error => {
                // setError(error);
                // setLoading(false);
            })
    }, []);

    // React.useEffect(() => {
    //     fetch(`${BACKEND_URL}/fetch-templates`, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //     .then((res: any) => setTemplateList(JSON.parse(res)))
    // }, [templateList]);

    const [lastValues, setLastValues] = React.useState({
        title: '',
        body_content: '',
        template_name: '',
        attachment: '',
        broadcast_date: [],
        customer_file: '',
        saveButtonClicked: true, // Default save button clicked true to trigger the dataProvider lookup on useEffect
    })


    const fetchTemplateInfo = useCallback(async (e: any) => {
        setHasAttachment(false)
        setHasHeader(false)
        setHasHeaderParams(false)
        setHasBodyParams(false);
        setBodyParams([])
        setHeaderParams([])
        setHasButton(false)
        setBtnParams([])
        // setHasFooter(false)
        // setFooterParams([])

        if (e.target) {
            console.log(e.target)
            fetch(`${BACKEND_URL}/fetch-template-info?` + new URLSearchParams({
                id: e.target.value
            }))
                .then((resp) => resp.json()) // Transform the data into json
                .then(function (data) {
                    console.log(data)
                    if (data.content && data.content != null) {
                        setHasBody(true)
                        setContentText(data.content)
                    }
                    setHasBodyParams(data.has_body_var);
                    if (data.has_body_var == true) {
                        setBodyParams(data.body_variables)
                    }
                    if (data.header_type == 1) {

                        setHasHeaderParams(data.has_header_var)
                        if (data.has_header_var == true) {
                            setHeaderText(data.header_content)
                            setHeaderParams(data.header_variables)
                        }

                    } else if (data.header_type == 2 || data.header_type == 3 || data.header_type == 4) {
                        setHasAttachment(true)
                        setHasHeader(true)
                        setFileType(data.file_type)
                        setPlaceholderAttachment(data.placeholder)
                    }

                    if (data.button_variables.length > 0) {
                        setHasButton(true)
                        setBtnText(data.button_text)
                        setBtnParams(data.button_variables)

                    }
                    // if (data.footer_variables.length > 0){
                    //     setHasFooter(true)
                    //     setFooterText(data.footer_text)
                    //     setFooterParams(data.footer_variables)
                    // }
                })
        }

    }, []) // update the callback if the state changes

    const saveButtonHandler = (e: any) => {
        setLastValues({ ...lastValues, saveButtonClicked: true })
    }

    const onSuccess = (data: any) => {
        notify(`Promotion "${data.broadcast_name}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    const onFailure = (error: any) => {
        notify(`Failed to create Promotion: ${error.message}`)
        redirect('/promotions');
        refresh();
    };

    const globalDateValidation = (value: any) => {
        if (value && value != undefined && value.length > 0) {
            if (value.length == 5) setDisableAddDate(true)
            var b_date: string[] = []
            value.forEach(function (obj: any, index: any) {
                if (obj != undefined) {
                    var c_date = moment(obj.date).format("YYYY-MM-DD HH:mm")
                    if (b_date.indexOf(c_date) > -1) {
                        return 'Duplicate date';
                    } else {
                        b_date.push(c_date);
                    }
                }

            });
        }
        return []
    };


    React.useEffect(() => {
        if (lastValues.saveButtonClicked) {
            setLastValues({ ...lastValues, saveButtonClicked: false })
        }
    }, [lastValues])

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        for (var i = 0; i < data.broadcast_date.length; i++) {
            data.broadcast_date[i].converted_date = moment(data.broadcast_date[i].date).format("YYYY-MM-DD HH:mm")
        }
        return {
            ...data
        }
    }

    return (

        <Create title={translate('resources.promotions.form.create')} onSuccess={onSuccess} onFailure={onFailure} transform={transformRecord}  {...props} >
            <TabbedForm toolbar={<BottomToolbar saveButtonClick={saveButtonHandler} />} defaultValues={{ broadcast_name: lastValues.title, template_name: lastValues.template_name, file_en: lastValues.attachment, customer_file: lastValues.customer_file, broadcast_date: lastValues.broadcast_date }}>
                <FormTab label="Promotion">

                    <TextInput variant="outlined" source="broadcast_name"
                        resettable={true}
                        label={translate('resources.promotions.fields.broadcast_name')}
                        helperText={translate('resources.promotions.helperText.broadcast_name')}
                        fullWidth validate={validateTitle} />

                    <SelectInput variant="outlined" fullWidth resettable={true} validate={[required()]}
                        label={translate('resources.promotions.fields.template_name')} optionValue="id"
                        helperText={translate('resources.promotions.helperText.template_name')}
                        onChange={fetchTemplateInfo} source="id" choices={templateList} />


                    {hasHeader ?
                        <div>
                            <br />
                            <InputLabel>Header</InputLabel>
                            <hr />
                        </div> : ''}

                    {hasAttachment ?
                        <FileInput multiple={false} label={translate('resources.promotions.fields.attachment')} variant="outlined" source="file_en" accept={fileType} placeholder={placeholderAttachment} validate={[required()]}>
                            <FileField source="src" title="title" />
                        </FileInput>
                        : ''}


                    {hasHeaderParams ?

                        <div>
                            <br />
                            <InputLabel>Header Parameters</InputLabel>
                            <hr />
                            <OutlinedInput
                                fullWidth={true}
                                id="outlined-header-text"
                                value={headerText}
                                multiline={true}
                                disabled />

                            {headerParams.map(function (object, index) {
                                {
                                    return object == '' ? ''
                                        : <TextInput key={index} label={object}
                                            variant="outlined"
                                            source={object}
                                            validate={validateHeaderFooter}
                                            fullWidth />
                                }
                            })}
                        </div>

                        : ''}


                    {hasBody ?
                        <div>
                            <br />
                            <InputLabel>Body</InputLabel>
                            <hr />

                            <OutlinedInput
                                fullWidth={true}
                                id="outlined-content"
                                value={contentText}
                                multiline={true}
                                disabled />
                        </div> : ''}

                    {hasBodyParams ?

                        <div>
                            <br />
                            <InputLabel>Content Parameters</InputLabel>
                            <br />
                            {bodyParams.map(function (object: string, index) {
                                {
                                    return object == '' ? ''

                                        : <TextInput key={index} label={object}
                                            variant="outlined"
                                            source={object.replace("'", "")}
                                            validate={validateContent}
                                            fullWidth />
                                }
                            })}
                        </div>

                        : ''}

                    {hasButton ?

                        <div>
                            <br />
                            <InputLabel>Buttons Parameters</InputLabel>
                            <hr />
                            {/* <OutlinedInput
                            fullWidth={true}
                            id="outlined-header-text"
                            value={btnText}
                            multiline={true}
                            disabled/> */}

                            {btnParams.map(function (object: string, index) {
                                {
                                    return object == '' ? ''
                                        : <TextInput key={index} label={object}
                                            variant="outlined"
                                            source={object.replace("'", "")}
                                            validate={[required()]}
                                            fullWidth />
                                }
                            })}
                        </div>
                        : ''}


                    {/* { hasFooter ?

                    <div className={classes.divparams}>
                        <br/>
                        <InputLabel>Footer Parameters</InputLabel>
                        <hr/> 
                        <OutlinedInput
                            fullWidth={true}
                            id="outlined-header-text"
                            value={footerText}
                            multiline={true}
                            disabled/>

                            {footerParams.map(function(object, index){
                            {return object == ''? ''
                                : <TextInput  key={index} label={object} 
                                variant="outlined" 
                                source={object} 
                                validate={validateHeaderFooter} 
                                fullWidth />
                            }
                            })} 
                        </div>
                    : '' } */}

                    <hr />

                    <FileInput multiple={false} label={translate('resources.promotions.fields.customer_list')} source="customer_file" variant="outlined" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={translate('resources.promotions.fields.file_upload')} validate={[required()]}>
                        <FileField source="src" title="title" />
                    </FileInput>

                    <InputLabel><a href={BACKEND_URL + '/fetch-customer-template?file=UCSI_Broadcast_Listing_Template.xlsx'} download>Customer List Template</a></InputLabel>


                    <br />

                </FormTab>
                <FormTab label="Broadcast Schedule">
                    <ArrayInput source="broadcast_date">
                        <SimpleFormIterator disableAdd={disableAddDate}>
                            <DateTimeInput label={translate('resources.promotions.fields.broadcast_date')} source="date" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export default PromotionCreate