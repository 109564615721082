import { Admin, Resource, CustomRoutes } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { englishMessages } from './i18n/en';
import { authProvider } from './auth-provider';
import { lightTheme, nordTheme } from './layout/themes';
import AppLoginPage from './login';
import { Layout } from './layout';
import User from './users';
import Role from './roles';
import Response from './bot-responses'
import Intent from './bot-intent'
import Prediction from './predictions'
import Model from './bot-models'
import Contact from './contacts'
import Promotion from './promotion'
import Template from './broadcast-templates'
import Conversation from './conversations'
import ConversationHistories from './conversation-histories'
import permissions from './access-control'
import { AnalyticDashboard } from './analytic-dashboard'
import InternalConversation from './internal-conversation';
import dataProvider from './data-provider';
import Holiday from './live-agent-holiday'
import WorkingHour from './live-agent-working-hour'
import Lead from './leads'
import UserAccount from './user-account/UserAccount';
import { DataProvider } from './context/ConversationContext';
import { Route, RouteProps } from 'react-router-dom';

const i18nProvider = polyglotI18nProvider(locale => {
  // if (locale === 'fr') {
  //     return import('./i18n/fr').then(messages => messages.default);
  // }

  // Always fallback on english
  return englishMessages;
}, 'en');



const App = () => (


  <DataProvider>
    <Admin
      dashboard={AnalyticDashboard}
      loginPage={AppLoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={lightTheme}
      layout={Layout}>
      <Resource name="users" {...User} />
      <Resource name="roles" {...Role} />
      <Resource name="promotions" {...Promotion} />
      <Resource name="template" {...Template} />
      <Resource name="intent" {...Intent} />
      <Resource name="bot-responses" {...Response} />
      <Resource name="model" {...Model} />
      <Resource name="prediction" {...Prediction} />
      <Resource name="contacts" {...Contact} />
      <Resource name="leads" {...Lead} />
      <Resource name="conversations" {...Conversation} />
      <Resource name="conversation-histories" {...ConversationHistories} />
      <Resource name="internal-conversations" {...InternalConversation} />
      <Resource name="permission" {...permissions} />
      <Resource name="live-agent-holiday" {...Holiday} />
      <Resource name="live-agent-working-hour" {...WorkingHour} />
      <CustomRoutes>
        <Route path="/user-account" element={<UserAccount />} />
      </CustomRoutes>
    </Admin>
  </DataProvider>
)
export default App;

