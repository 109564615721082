import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import SecurityIcon from '@mui/icons-material/Security';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PeopleIcon from '@mui/icons-material/People';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ReviewsIcon from '@mui/icons-material/Reviews';
import HistoryIcon from '@mui/icons-material/History';
import CampaignIcon from '@mui/icons-material/Campaign';
import RadioIcon from '@mui/icons-material/Radio';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
    Menu
} from 'react-admin';

// import visitors from '../visitors';
// import orders from '../orders';
// import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from './sub-menu';
import users from '../users';
import roles from '../roles';
import responses from '../bot-responses'
import contacts from '../contacts'
import promotion from '../promotion'
import conversations from '../conversations'
import leads from '../leads'

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuTraining' | 'menuConversations' | 'menuBusinessHour' | 'menuCampaigns';

const MyMenu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuTraining: false,
        menuConversations: false,
        menuBusinessHour: false,
        menuCampaigns: false
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="app.menu.security"
                icon={<SecurityIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.user.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/roles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.role.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<roles.icon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuBusinessHour')}
                isOpen={state.menuBusinessHour}
                name="app.menu.business_hour"
                icon={<BusinessCenterIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/live-agent-holiday"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.holiday`, {
                        smart_count: 2,
                    })}
                    leftIcon={<EventIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/live-agent-working-hour"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.working_hour`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ScheduleIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCampaigns')}
                isOpen={state.menuCampaigns}
                name="app.menu.campaigns"
                icon={<CampaignIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/promotions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.broadcast`, {
                        smart_count: 2,
                    })}
                    leftIcon={<RadioIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/template"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.template`, {
                        smart_count: 2,
                    })}
                    leftIcon={<FileCopyIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
            </SubMenu>
            {/* @ts-ignore */}
            <MenuItemLink
                to="/leads"
                state={{ _scrollToTop: true }}
                primaryText={translate(`app.menu.leads`, {
                    smart_count: 2,
                })}
                leftIcon={<leads.icon sx={{ color: '#212121' }} />}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuConversations')}
                isOpen={state.menuConversations}
                name="app.menu.conversation"
                icon={<SupportAgentIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/conversations"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.externalConversation`, {
                        smart_count: 2,
                    })}
                    leftIcon={<WhatsAppIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/conversation-histories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.conversationHistory`, {
                        smart_count: 2,
                    })}
                    leftIcon={<HistoryIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                {/* <MenuItemLink
                    to="/internal-conversations"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.internalConversation`, {
                        smart_count: 2,
                    })}
                    leftIcon={<PeopleIcon sx={{ color: '#25D366' }}/>}
                    dense={dense}
                /> */}
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuTraining')}
                isOpen={state.menuTraining}
                name="app.menu.training"
                icon={<SmartToyIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/intent"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.intent.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<FormatAlignLeftIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/bot-responses"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.response.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ReviewsIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/model"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.model.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ReviewsIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/prediction"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.prediction.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ReviewsIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default MyMenu;

