import React, {useEffect, useState} from "react"
import Button from '@mui/material/Button';
import CancelIcon from "@mui/icons-material/Cancel"
import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, CheckboxGroupInput, SimpleForm, BooleanInput, TextInput, regex, email, required, minLength, maxLength } from 'react-admin';
import dataProvider from '../data-provider'



/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({basePath, ...props}: any) => {
    const redirect = useRedirect();
    
    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => redirect('/roles')} color="inherit" startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}


const RoleCreate = (props: any) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [permissionChoices, setPermissionChoices] = React.useState([])

    React.useEffect(() => {
        dataProvider.getMany('fetch-permission-choices', { ids: [] })
            .then(({data}: any) => {
                console.log(data)
                setPermissionChoices(data);
            })
            .catch(error => {
                // setError(error);
                // setLoading(false);
            })
    }, []);


    /**
     * Create Form submission failure handling
     * @param {} param0 
     */

    const onCreateFailure = (error: any) => {
        notify(`Failed to create role: ${error.message}`)
        redirect('/roles');
        refresh();
    };

    const onSuccess = (data: any) => {
        notify(`Role "${data.role_name}" successfully created`)
        redirect('/roles');
        refresh();
    };

    /**
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        console.log(data)
        data.role = {}
        data.role.enabled = data.enabled
        data.role.role_name =  data.role_name
        return {
            ...data
        }
    }

    return (
        <Create title={translate('resources.role.form.create')} onSuccess={onSuccess} onFailure={onCreateFailure} transform={transformRecord}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />}  >
                <br/>
                <BooleanInput variant="outlined" source="enabled"
                    label={translate('resources.role.fields.enabled')}
                    helperText={translate('resources.role.helperText.enabled')}
                    fullWidth/>
                <br/>
                <TextInput variant="outlined" source="role_name"
                    resettable={true}
                    label={translate('resources.role.fields.name')} 
                    helperText={translate('resources.role.helperText.name')} 
                    fullWidth/>

                {permissionChoices.map(function(object: any, index){
                    return    <CheckboxGroupInput key={index} label={object.module} source="permissions" choices={object.permissions} optionValue="id" optionText="name"/>
                })}
            </SimpleForm>
        </Create>
    )
}

export default RoleCreate