import { AuthProvider, UserIdentity } from 'react-admin';
import { BACKEND_URL } from './constants';
import decodeJwt from 'jwt-decode';
import Profile from './assets/profile.png';

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        const request = new Request(`${BACKEND_URL}/auth`, {
            method: "POST",
            body: formData,
        });
        return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then( ({access_token, ...rest}) => {
                    const decodedToken: any = decodeJwt(access_token);
                    sessionStorage.setItem('identity', rest.identity);
                    sessionStorage.setItem('fullname', rest.fullname);
                    sessionStorage.setItem('token', access_token);
                    sessionStorage.setItem('scopes', decodedToken.scopes);
                    sessionStorage.setItem('agent_id', rest.live_agent_id);
                })
    },
    logout: () => {
        sessionStorage.removeItem('fullname');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('scopes');
        sessionStorage.removeItem('identity');
        sessionStorage.removeItem('agent_id');
        sessionStorage.removeItem('university_id');
        return Promise.resolve()
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('fullname');
            sessionStorage.removeItem('scopes');
            sessionStorage.removeItem('identity');
            sessionStorage.removeItem('agent_id');
            sessionStorage.removeItem('university_id');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: ({enabled, params}) => {
        const token = sessionStorage.getItem('token')
        if (token) {
            const decodedToken: any = decodeJwt(token);
            const tokenExpired = new Date().setUTCSeconds(decodedToken.exp) < new Date().getTime();
            return tokenExpired ? Promise.reject({redirectTo: '/login'}) : Promise.resolve()
        } else {
            return Promise.reject({ redirectTo: '/login'})
        }

    },
    getPermissions: () => {
        let scopes: string | null = sessionStorage.getItem("scopes");
        let permissions: string[] = scopes ? scopes.split(","): []
        return permissions && permissions.length > 0 ? Promise.resolve(permissions) : Promise.reject();
    },
    getIdentity: () => {
        return Promise.resolve<UserIdentity>({
            id: 0,
            fullName: sessionStorage.getItem("fullname") || '',
            avatar: Profile,
        });
    },
};