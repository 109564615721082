import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslate } from 'react-admin';
import { Stack, Box, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControl, FormGroup, FormControlLabel, Grid, Button, Container } from '@mui/material';
import OnlineEnquiry from './components/online-enquiry';
import StudentCompositionChart from './components/student-composition';
import ProgramCompositionChart from './components/program-composition';
import CourseNonCourseEnquiry from './components/course-non-course-enquiry';
import SuccessfulResponse from './components/successful-response';
import ConversionChart from './components/inquiry-to-new-leads';
import RequestEnquiry from './components/request-enquiry';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useState, useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
});


const AnalyticDashboard = () => {
  const translate = useTranslate()
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState<string[]>([]);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleDownloadPDF = async () => {
    if (selectedCharts.length === 0) {
      openDialog();
      return;
    }

    const chartsPerPage = 4; // Maximum number of charts per page
    let currentPage = 1;

    // Create a new jsPDF instance
    const pdf = new jsPDF();

    for (let index = 0; index < selectedCharts.length; index++) {
      const chartId = selectedCharts[index];
      console.log(chartId)
      // Get the chart element to capture
      const chartElement = chartId ? document.getElementById(chartId) : null;

      if (chartElement) {
        // Use html2canvas to capture the chart as an image
        const canvas = await html2canvas(chartElement, { allowTaint: true, backgroundColor: 'white', removeContainer: true });

        // Calculate the width and height for the PDF document
        const pdfWidth = 210;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        if (index % chartsPerPage === 0) {
          // If it's the first chart on a new page, add a new page
          if (index !== 0) {
            pdf.addPage();
            currentPage++;
          }
        }

        // Calculate the position for the chart on the page
        const chartIndexOnPage = index % chartsPerPage;
        const columnCount = 2; // Number of columns per page
        const row = Math.floor(chartIndexOnPage / columnCount);
        const column = chartIndexOnPage % columnCount;
        const pageX = (pdfWidth / 2) * column;
        const pageY = (pdfHeight / 2) * row;

        // Add the captured chart image to the PDF document
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          pageX,
          pageY,
          pdfWidth / 2,
          pdfHeight / 2
        );

        if (index === selectedCharts.length - 1) {
          // If it's the last chart, download the PDF file
          pdf.save('charts.pdf');
        }
      } else {
        console.error(`Element with ID '${chartId}' not found.`);
      }
    }
  };

  const handleChartSelection = (chartId: string) => {
    if (selectedCharts.includes(chartId)) {
      setSelectedCharts(selectedCharts.filter((id) => id !== chartId));
    } else {
      setSelectedCharts([...selectedCharts, chartId]);
    }
  };

  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(1, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  // Function to disable end dates earlier than the selected start date
  const shouldDisableDate = (day: any) => {
    console.log(day)
    // Convert selectedStartDate and day to Date objects
    const startDate = selectedStartDate ? new Date(selectedStartDate.toDate()) : null;
    const endDate = new Date(day);

    // If startDate is not set, don't disable any dates
    if (!startDate) {
      return false;
    }

    return endDate < startDate;
  };

  return (
    <ThemeProvider theme={theme}>
      <br />
      <Box className='analytic-dashboard'>
        <Stack spacing={2} direction="row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-input': {
                    backgroundColor: 'white',
                  },
                  width: '100%',
                  '@media (min-width: 600px)': {
                    minWidth: 200,
                    maxWidth: 200,
                  },
                }}
                label="From"
                value={selectedStartDate}
                onChange={(date: any) => setSelectedStartDate(date)}
                disableFuture
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-input': {
                    backgroundColor: 'white',
                  },
                  width: '100%',
                  '@media (min-width: 600px)': {
                    minWidth: 200,
                    maxWidth: 200,
                  },
                }}
                label="To"
                value={selectedEndDate}
                onChange={(date: any) => setSelectedEndDate(date)}
                disableFuture
                shouldDisableDate={(day: any) => shouldDisableDate(day) === true && day !== selectedStartDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
        <Button
          id='button'
          variant="contained"
          color="primary"
          onClick={openDialog}
          sx={{
            fontSize: '14px',
            width: '40%',
            padding: '8px 16px',
            margin: '8px',
            '@media (min-width: 600px)': {
              width: '20%',
            },
          }}
        >
          Download Charts
        </Button>
      </Box>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Select Charts</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("online-enquiry")}
                    onChange={() => handleChartSelection("online-enquiry")}
                  />
                }
                label="Online Enquiries/ Conversations"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("successful-response")}
                    onChange={() => handleChartSelection("successful-response")}
                  />
                }
                label="Predictions"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("conversion-chart")}
                    onChange={() => handleChartSelection("conversion-chart")}
                  />
                }
                label="Leads"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("course-non-course-enquiry")}
                    onChange={() => handleChartSelection("course-non-course-enquiry")}
                  />
                }
                label="Courses"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("request-enquiry")}
                    onChange={() => handleChartSelection("request-enquiry")}
                  />
                }
                label="Live Agent Request"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("program-composition")}
                    onChange={() => handleChartSelection("program-composition")}
                  />
                }
                label="Program Enquiries"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("student-composition")}
                    onChange={() => handleChartSelection("student-composition")}
                  />
                }
                label="Student's Nationality and Highest Qualifciations"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadPDF} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} id="online-enquiry">
          {/* <Checkbox
      checked={selectedCharts.includes("online-enquiry")}
      onChange={() => handleChartSelection("online-enquiry")}
    /> */}
          <OnlineEnquiry selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid><Grid item xs={12} sm={6} md={4} lg={3} id="successful-response">
          {/* <Checkbox
      checked={selectedCharts.includes("successful-response")}
      onChange={() => handleChartSelection("successful-response")}
    /> */}
          <SuccessfulResponse selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid><Grid item xs={12} sm={6} md={4} lg={3} id="conversion-chart">
          {/* <Checkbox
      checked={selectedCharts.includes("conversion-chart")}
      onChange={() => handleChartSelection("conversion-chart")}
    /> */}
          <ConversionChart selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid><Grid item xs={12} sm={6} md={4} lg={3} id="course-non-course-enquiry">
          {/* <Checkbox
      checked={selectedCharts.includes("course-non-course-enquiry")}
      onChange={() => handleChartSelection("course-non-course-enquiry")}
    /> */}
          <CourseNonCourseEnquiry selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} id="request-enquiry">
          <RequestEnquiry selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid>
        <br />
        {/* <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
        </Grid> */}
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} id="program-composition">
          <ProgramCompositionChart selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} id="student-composition">
          <StudentCompositionChart selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default AnalyticDashboard;