import LeadsList from './leads-list'
import ContactCreate from './contact-create'
import GroupIcon from '@mui/icons-material/Group';
import ContactShow from './contact-show'
import ContactEdit from './contact-edit';

export default {
    // create: ContactCreate,
    // edit: ContactEdit,
    list: LeadsList,
    // show: ContactShow,
    icon: GroupIcon,
}